//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import rightOffsetCalculator from '@/mixinsjs/rightOffsetCalculator';

export default {
    mixins: [rightOffsetCalculator],
    props: {
        slides: {
            type: Array,
            default: () => [],
        },
    },
    data() {
        return {
            activeTabIndex: 0,
            tabsRightOffset: 0,
            delimitersWidth: '300',
            icons: [
                '/images/compass-icon.svg',
                '/images/chart-icon.svg',
                '/images/content-icon.svg',
                '/images/chat-icon.svg',
            ],
        };
    },
    computed: {
        direction() {
            return this.$vuetify.rtl ? 'right' : 'left';
        },
        sliderAssetBaseUrl() {
            return process.env.VUE_APP_API_STORAGE_URL;
        },
    },
    watch: {
        activeTabIndex(v) {
            this.tabChangeHandler(v);
        },
    },
    mounted() {
        this.calculateDelimitersWidth();
        window.addEventListener('resize', this.calculateDelimitersWidth);
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.calculateDelimitersWidth);
    },
    methods: {
        calculateDelimitersWidth() {
            this.delimitersWidth = this.$refs['carousel-wrapper'].clientWidth / 4;
        },
        tabChangeHandler(tabIndex) {
            this.tabsRightOffset = this.rightOffsetCalculator(
                this.slides.length,
                tabIndex,
                4,
                this.delimitersWidth
            );
        },
    },
};
