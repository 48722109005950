export default {
    methods: {
        rightOffsetCalculator(length, index, limit, width) {
            if (limit > length) {
                return 0;
            }
            if (index < 2) {
                return 0;
            }
            if (index > length - limit + 1) {
                return -1 * (length - limit) * width;
            }
            return -1 * (index - 1) * width;
        },
    },
};
